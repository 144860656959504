<template>
  <div>
    <div class="flex justify-between">
      <h4 class="text-lg font-bold">限量预售的城市总库存</h4>
    </div>

    <!-- <div>
      <a-tabs type="card" class="w-full" @change="handlerCityChange" :activeKey="searchForm.outlets_city_adcode">
        <a-tab-pane v-for="item in cityList" :key="item.adcode" :tab="item.name"></a-tab-pane>
      </a-tabs>
    </div> -->
    
    <!-- 城市库存表 -->
    <base-table ref="saleGoods"
      rowKey="sku_id"
      :columnsData="columns_city"
      :tableData="tableDataCity"
      :addHeight="10"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count" />

    <!-- 设置城市限量库存 -->
    <a-modal title="设置城市限量库存"
        v-if="isShowEditStock"
        :visible.sync="isShowEditStock"
        destroyOnClose
        width="700px"
        @ok="handlerEditOk"
        @cancel="isShowEditStock=false">
      <div>
        <base-table
          rowKey="sku_id"
          :columnsData="columns_stock"
          :tableData="tableData_stock">
          <template #toolbar>
            <div class="w-full mb-2 flex justify-between">
              <div slot="tabBarExtraContent" class="flex">
                <span class="font-bold">{{activeRow && activeRow.goods_name}}</span>
                <span class="ml-1 mr-1">-</span>
                <!-- <span class="font-bold">{{activeCity && activeCity.name}}</span> -->
                <span class="font-bold">{{editCityForm.city_name}}</span>
                <span class="ml-2">城市总库存</span>
              </div>
            </div>
          </template>
          <template #stock="{record}">
            <a-input-number v-model="record.stock" />
          </template>
        </base-table>
      </div>
    </a-modal>
  </div>
</template>

<script>

import {
  getFinanceUnionCityList,
} from "@/api/outlets"
import {
  getCityPresaleGoods,
  editCityPresaleStock,
} from "@/api/regional/goods"

import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'

import { rowSpan } from "@/utils/index"
// import { formatGoodsType } from "@/utils/type";
// import { cutQuery } from '@/utils/ossImg'

export default {
  components: {

  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      isCityManage: isCityManage(),
      manageCityList: [],
      cityList: [],

      // activeCity: null,
      searchForm: {
        outlets_city_adcode: "",
        page: 1,
        page_count: 40,
      },

      columns_city: [],
      tableDataCity: [],
      total: 0,

      baseColumns: [
        // {
        //   title: "产品属性",
        //   dataIndex: "goods_type",
        //   align: "center",
        //   width: 80,
        //   fixed: "left",
        //   customRender: (text, row) => {
        //     return {
        //       children: formatGoodsType(text),
        //       attrs: {
        //         rowSpan: row.goods_idRowSpan, //需要合并的数据
        //       },
        //     }
        //   },
        // },
        // {
        //   title: "图片",
        //   dataIndex: "cover_url",
        //   align: "center",
        //   width: 80,
        //   fixed: "left",
        //   customRender: (text, row) => {
        //     if(text) text = text+cutQuery(50,50)
        //     return {
        //       children: <img style="height:50px;" src={text}></img>,
        //       attrs: {
        //         rowSpan: row.goods_idRowSpan, //需要合并的数据
        //       },
        //     }
        //   },
        // },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: 200,
          fixed: "left",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_idRowSpan, //需要合并的数据
              },
            }
          },
        },
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          width: 120,
          fixed: "left",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          width: 120,
          fixed: "left",
        },
        // {
        //   title: "限量总库存",
        //   dataIndex: "initial_stock",
        //   align: "center",
        //   width: 100,
        // },
        // {
        //   title: "限量剩余库存",
        //   dataIndex: "stock",
        //   align: "center",
        //   width: 110,
        // },
      ],

      columns_stock: [
        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
        },
        // {
        //   title: "限量总库存",
        //   dataIndex: "initial_stock",
        //   align: "center",
        //   width: 140,
        //   slots: { customRender: "initial_stock" },
        // },
        {
          title: "限量剩余库存",
          dataIndex: "stock",
          align: "center",
          width: 140,
          slots: { customRender: "stock" },
        },
      ],
      tableData_stock: [],

      isShowEditStock: false,
      activeRow: null,
      editCityForm: {
        outlets_city_adcode: "",
      },

      loading: false,
    };
  },
  async mounted() {
    this.manageCityList = await getMarketCityList(true)
    const { code, data } = await getFinanceUnionCityList()
    if(code == 0){
      let cityList = []
      data.list.map(el=>{
        if(this.manageCityList.some(c=>c.adcode == el.adcode)){
          cityList = cityList.concat(el.city_list)
        }
      })
      this.cityList = cityList
      if(cityList.length){
        const list = cityList.map(el=>{
          const key = "stock_"+el.adcode
          return {
            title: el.name,
            dataIndex: key,
            align: "center",
            width: 80,
            customRender: (city, row) => {
              return {
                children: (
                  <div>
                    <a-button class="p-0" type="link"
                      onClick={() => {
                        this.handlerEditStock(row, city)
                      }}
                    >{ city.stock==-1 ? '——' : city.stock }</a-button>
                  </div>
                ),
              }
            },
          }
        })
        if(cityList.length > 1){
          list.unshift({
            title: "库存总和",
            dataIndex: "stock_total",
            align: "center",
            width: 80,
            fixed: "left"
          })
        }
        this.columns_city = this.baseColumns.concat(list)
        this.searchForm.outlets_city_adcode = cityList.map(el=>el.adcode).join(",")
        // this.activeCity = cityList[0]
        this.handlerSearch()
      }
    }
  },
  methods: {
    async initData() {
      const { data, code } = await getCityPresaleGoods(this.searchForm);
      if (code === 0) {
        let goods_list = data.list
        // 组装表格所需数据
        let skuArr = []
        goods_list.map((goods) => {
          goods.sku_data.map((item) => {
            let tmp_data = {
              goods_id: goods.goods_id,
              goods_name: goods.goods_name,
              goods_type: goods.goods_type,
              cover_url: goods.cover_url,
              flavor_name: item.flavor_name,
            }
            if (item.specification_list.length > 0) {
              item.specification_list.map((el) => {
                const sku = {
                  ...tmp_data,
                  specification_name: el.specification_name,
                  sku_id: el.sku_id,
                  price: el.price,
                }
                let stock_total = 0
                this.cityList.map(c=>{
                  const city = el.city_stock.find(city=>city.adcode==c.adcode)
                  sku["stock_"+c.adcode] = {
                    "city_name": c.name,
                    "city_adcode": c.adcode,
                    "stock": city ? city.stock : -1,
                  }
                  if(city && city.stock){
                    stock_total = stock_total + city.stock
                  }
                })
                sku["stock_total"] = stock_total
                skuArr.push(sku)
              })
            }
          })
        })
        skuArr = rowSpan(skuArr, "goods_id")

        this.tableDataCity = skuArr
        this.total = data.total_count
      }
    },
    handlerSearch(){
      // if(!this.activeCity){
      if(!this.searchForm.outlets_city_adcode){
        this.$message.info("非城市账号")
        return
      }
      // this.searchForm.outlets_city_adcode = this.activeCity.adcode
      this.searchForm.page = 1
      this.initData();
    },

    // handlerCityChange(val){
    //   this.activeCity = this.cityList.find(el=>el.adcode==val)
    //   this.handlerSearch()
    // },

    handlerEditStock(row, city_data){
      this.activeRow = row
      this.editCityForm = {
        outlets_city_adcode: city_data.city_adcode,
        city_name: city_data.city_name,
      }

      this.tableData_stock = this.tableDataCity.filter(sku=>sku.goods_id == row.goods_id).map(el=>{
        const stock = el["stock_"+city_data.city_adcode].stock
        return {
          flavor_name: el.flavor_name,
          specification_name: el.specification_name,
          sku_id: el.sku_id,
          stock: stock==-1 ? "" : stock
        }
      })

      this.isShowEditStock = true
    },
    async handlerEditOk(){
      let update_sku_data = this.tableData_stock.map(el=>{
        return {
          sku_id: el.sku_id,
          stock: el.stock,
          initial_stock: 0, // 因为暂时用不上城市总库存
        }
      })
      const {code} = await editCityPresaleStock({
        outlets_city_adcode: this.editCityForm.outlets_city_adcode,
        update_sku_data: JSON.stringify(update_sku_data)
      })
      if(code == 0){
        this.isShowEditStock = false
        this.$message.success("已保存")
        this.initData()
      }
    },

  },
};
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
